<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div>
      <!-- 查询 -->
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="活动名称">
          <el-input size="small" v-model="mechanism_name" id="name" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入订单数量" v-model="fackNum" clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="fackType" placeholder="请选择活动" value-key="id">
            <el-option
              v-for="(item, index) in ActivityList"
              :key="index"
              :label="item.name"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="addFackData">添加数据</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div class="PERInformation-table">
        <h4></h4>
        <div class="tables">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :cell-style="cellstya"
            border
            style="width: 100%"
          >
            <el-table-column prop="avatar" label="活动封面" align="center">
              <template slot-scope="scope">
                <img :src="scope.row.background_image" style="height: 50px" />
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="活动名称"
              align="center"
            ></el-table-column>
            <!-- <el-table-column
              prop="mobile"
              label="数据类型"
              align="center"
            ></el-table-column> -->

            <el-table-column
              prop="create_time"
              label="创建时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="update_time"
              label="更新时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="register_platform"
              label="操作"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="(mechanism_id = scope.row.id), (YNaddData = true)"
                  >添加数据</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <paging-fy
            @currentPageChange="handleCurrentChange"
            :currentPage="currentPage"
            :total="total"
          ></paging-fy>
        </div>
      </div>
    </div>

    <el-dialog title="添加数据" :visible.sync="YNaddData" :width="ks?'95%':'35%'">
      <el-form>
        <el-form-item label="订单数量">
          <el-input v-model="fackNum" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="YNaddData = false">取 消</el-button>
        <el-button type="primary" @click="addFackData">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "",
  ks:'',
  data() {
    return {
      nav: {
        firstNav: "活动管理中心",
        secondNav: "数据管理",
      },
      YNaddData: false,
      mechanism_id: "",
      tableData: [],
      total: 0,
      currentPage: 1,
      mechanism_name: "",
      fackNum: "",
      fackType: "",
      ActivityList: [],
    };
  },
  created() {
    this.GetList();
    this.GetActivityList();
    this.ks=window.screen.width<768
  },
  methods: {
    addFackData() {
      let commodity_type = "";
      if (this.fackType.name == "优学·天赋卡") {
        commodity_type = "A卡";
      }
      let url = "/user/pay/insertRobot",
        data = {
          count: this.fackNum,
          activity_id: this.mechanism_id,
          commodity_type,
        };
      this.$axios.post(url, data).then((res) => {
        this.$message(res.data.message);
        this.YNaddData = !this.YNaddData;
      });
    },
    cellstya() {
      return "text-align:center";
    },
    GetActivityList() {
      this.$axios
        .get(
          "/user/businessActivityType/queryManagerListPage?status=2&pageSize=20"
        )
        .then((res) => {
          this.ActivityList = res.data.data.rows;
        });
    },
    GetList() {
      this.tableData = [];
      this.$axios
        .get(
          "/user/businessActivityType/queryManagerListPage?pageSize=10&status=2&currentPage=" +
            this.currentPage
        )
        .then((res) => {
          this.total = res.data.data.total;
          this.tableData = res.data.data.rows;
        });
    },

    onSubmit() {
      if (this.mechanism_name == "") {
        this.GetList();
      } else {
        this.$axios
          .get(
            "/user/businessActivityType/queryByMessage?name=" +
              this.mechanism_name
          )
          .then((res) => {
            console.log(res.data.data);
            this.tableData = res.data.data;
            this.total = 1;
          });
      }
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      // this.GetNumbers();
      this.GetList();
    },
  },
};
</script>
<style scoped>
.flex-row {
  width: 100%;
  display: flex;
  flex-grow: 0 0 100%;
  justify-content: space-around;
}
</style>
